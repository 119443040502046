@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/mixins/breakpoints";

.plcc-page {
    margin: 0 auto;
    max-width: 877px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $space-16;
    }

    p {
        font-size: $font-14;
        font-weight: $font-medium;
    }
}

@mixin heading-style($font-family, $font-weight, $font-size, $line-height) {
    * {
        color: $color-t300 !important;
        font-family: $font-family !important;
        font-size: $font-size !important;
        font-weight: $font-weight !important;
        line-height: $line-height !important;
    }
}

.extra-large-heading---qg {
    @include heading-style($font-questa, $font-semi-bold, $font-46, 55.2px);
}

.large-heading---qg {
    @include heading-style($font-questa, $font-black, $font-36, 43.2px);
}

.medium-heading---qg {
    @include heading-style($font-questa, $font-black, $font-32, 38.4px);
}

.small-heading---qg {
    @include heading-style($font-questa, $font-black, $font-29, 34.8px);
}

.extra-small-heading---qg {
    @include heading-style($font-questa, $font-black, $font-26, 31.2px);
}

.extra-large-heading---sp {
    @include heading-style($font-sofia, $font-bold, $font-46, 55.2px);
}

.large-heading---sp {
    @include heading-style($font-sofia, $font-bold, $font-36, 43.2px);
}

.medium-heading---sp {
    @include heading-style($font-sofia, $font-bold, $font-32, 38.4px);
}

.small-heading---sp {
    @include heading-style($font-sofia, $font-bold, $font-26, 31.2px);
}

.extra-small-heading---sp {
    @include heading-style($font-sofia, $font-bold, 24px, 30px);
}

@mixin body-style($font-size, $line-height) {
    * {
        color: $color-t300 !important;
        font-family: $font-sofia !important;
        font-size: $font-size !important;
        font-weight: $font-medium !important;
        line-height: $line-height !important;
    }
}

.large-body---sp {
    @include body-style($font-18, 21.6px);
}

.medium-body---sp {
    @include body-style($font-16, 19.2px);
}

.small-body---sp {
    @include body-style($font-14, 18px);
}
